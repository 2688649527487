import React from 'react'
import { createRoot } from 'react-dom/client'
import { App } from './components/App'

import { initializeApp } from 'firebase/app'

const firebaseConfig = {
  apiKey: 'AIzaSyDyxMT0ovdPkt4I_6TZmBzdBySoHg0v2Yc',
  authDomain: 'imagemart-6a1fa.firebaseapp.com',
  projectId: 'imagemart-6a1fa',
  storageBucket: 'imagemart-6a1fa.appspot.com',
  messagingSenderId: '144958033896',
  appId: '1:144958033896:web:de82711d6b589400095e5d',
  measurementId: 'G-TP88DXDBDF',
}
initializeApp(firebaseConfig)

createRoot(document.getElementById('app') as Element).render(<App />)
