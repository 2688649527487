export const ja = {
  global: {
    siteName: 'ImageMart',
  },
  auth: {
    signIn: 'ログイン',
    signOut: 'ログアウト',
    failedToSignIn: 'ログインに失敗しました',
    signInWithGoogle: 'Googleアカウントでログインする',
    signInNotice: '招待メールを受け取った方のみログインできます。',
    signOutConfirmation: 'ログアウトします。よろしいですか？',
    cancelSigningOut: 'キャンセル',
    aboutTerms1: '下のボタンをクリックすることで、当サービスの',
    terms: '利用規約',
    aboutTerms2: 'に同意したものとします。',
  },
  about: {
    description: 'ImageMartはAIで画像から画像を生成するサービスです。',
    betaVersion: {
      title: 'ベータ版のご案内',
      description:
        'メールアドレスを登録してください。ベータ版が利用可能になったらお知らせします。',
      emailPlaceholder: 'メールアドレス',
      submitButtonText: '登録',
      registered: '登録しました！',
    },
    sampleImages: 'サンプル画像',
  },
  status: {
    waiting: '待機中',
    processing: '処理中',
    done: '完了',
    failed: 'エラー',
  },
  home: {
    requestImageGenerationTitle: '画像生成をリクエストする',
    withUrl: 'URLを指定',
    withLocalFile: '画像ファイルを指定',
    urlPlaceholder: '画像のURL',
    requestImageGeneration: 'リクエストする',
    createdAt: '作成日時',
    doneAt: '完了日時',
    status: '状態',
    inputImage: '入力した画像',
    generatedImages: '生成された画像',
    copyrightNotice: 'この画像は他者の著作権を侵害していません',
    notice:
      'このサービスは現在ベータ版です。\n多くの方にサービスを体験していただくために、生成される画像のクオリティを少し下げています。ご了承ください。',
  },
  registration: {
    invalidUrl: '無効なURLです。',
    alreadyRegistered: 'すでに登録済みです。',
    title: 'ようこそImageMartへ',
    errorMessage1:
      'ログインに失敗しました。Googleアカウントのメールアドレスが、招待が送られたメールアドレスと一致していない可能性があります。',
    errorMessage2:
      'こちらからGoogleアカウントのメールアドレスを設定して再度お試しください。',
    emailPlaceholder: 'メールアドレス',
    changeEmail: '変更',
    emailChanged: 'メールアドレスが変更されました。',
  },
}
