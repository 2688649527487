import { ListItem, UnorderedList } from '@chakra-ui/react'
import React, { FC } from 'react'
import { Link } from '../Link'

export const AdminHome: FC = () => {
  return (
    <UnorderedList>
      <ListItem>
        <Link to="/admin/invitationRequests">招待管理</Link>
      </ListItem>
      <ListItem>
        <Link to="/admin/gallery">トップに表示する画像管理</Link>
      </ListItem>
    </UnorderedList>
  )
}
