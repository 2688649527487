import {
  Box,
  Button,
  chakra,
  Heading,
  Input,
  Spinner,
  Stack,
  useBoolean,
} from '@chakra-ui/react'
import {
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithPopup,
} from 'firebase/auth'
import {
  collection,
  doc,
  DocumentData,
  DocumentSnapshot,
  getDoc,
  getFirestore,
  updateDoc,
} from 'firebase/firestore'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { translate } from '../i18n'
import { call } from '../utils/call'
import { Link } from './Link'

export const Registration: FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const invitationRequestId = useMemo(
    () => new URLSearchParams(location.search).get('invitation'),
    [location]
  )
  const [status, setStatus] = useState<
    'ok' | 'alreadyRegistered' | 'invalid' | undefined
  >()
  const [inProgress, { on, off }] = useBoolean()
  const [isFailed, setIsFailed] = useState(false)
  const [email, setEmail] = useState('')
  const [isEmailChanged, setIsEmailChanged] = useState(false)

  const invitationRequestsCollection = useMemo(
    () => collection(getFirestore(), 'invitationRequests'),
    []
  )

  useEffect(() => {
    if (invitationRequestId === null) {
      setStatus('invalid')
      return
    }

    call(async () => {
      const isSignedIn = await new Promise<boolean>((resolve) => {
        const unsubscribe = onAuthStateChanged(getAuth(), (user) => {
          resolve(user !== null)
          unsubscribe()
        })
      })
      if (isSignedIn) {
        navigate('/')
        return
      }

      let snapshot: DocumentSnapshot<DocumentData>
      try {
        snapshot = await getDoc(
          doc(invitationRequestsCollection, invitationRequestId)
        )
      } catch (e) {
        setStatus('invalid')
        return
      }

      if (!snapshot.exists()) {
        setStatus('invalid')
        return
      }

      if (snapshot.data()?.isRegistered) {
        setStatus('alreadyRegistered')
        return
      }

      setStatus('ok')
    })
  }, [invitationRequestId, invitationRequestsCollection, navigate])

  const signIn = useCallback(async () => {
    on()
    try {
      await signInWithPopup(getAuth(), new GoogleAuthProvider())
    } catch (e) {
      setIsFailed(true)
      off()
      return
    }

    await updateDoc(
      doc(invitationRequestsCollection, invitationRequestId as string),
      { isRegistered: true }
    )

    navigate('/')
    off()
  }, [invitationRequestId, invitationRequestsCollection, navigate, off, on])

  const changeEmail = useCallback(async () => {
    setIsEmailChanged(false)

    await updateDoc(
      doc(invitationRequestsCollection, invitationRequestId as string),
      { email }
    )

    setIsEmailChanged(true)
  }, [email, invitationRequestId, invitationRequestsCollection])

  if (status !== 'ok') {
    return (
      <Box
        maxW="600px"
        mx="auto"
        display="flex"
        h="100%"
        alignItems="center"
        justifyContent="center"
      >
        {
          {
            nop: <Spinner />,
            invalid: (
              <chakra.p>{translate('registration.invalidUrl')}</chakra.p>
            ),
            alreadyRegistered: (
              <chakra.p>{translate('registration.alreadyRegistered')}</chakra.p>
            ),
          }[status ?? 'nop']
        }
      </Box>
    )
  }

  return (
    <Box maxW="600px" mx="auto">
      <Heading as="h2" mt={2} mb={3}>
        {translate('registration.title')}
      </Heading>

      <chakra.p mb={1}>
        {translate('auth.aboutTerms1')}
        <Link to="/terms" target="_blank">
          {translate('auth.terms')}
        </Link>
        {translate('auth.aboutTerms2')}
      </chakra.p>

      <Button onClick={signIn} disabled={inProgress}>
        {translate('auth.signInWithGoogle')}
      </Button>

      {isFailed && (
        <Box bg="pink.100" p={2} rounded="md" mt={4}>
          <chakra.p color="red.800" mb={2}>
            {translate('registration.errorMessage1')}
            <br />
            {translate('registration.errorMessage2')}
          </chakra.p>
          <Stack direction="row" mb={2}>
            <Input
              type="email"
              bg="pink.50"
              placeholder={translate('registration.emailPlaceholder')}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button colorScheme="orange" onClick={changeEmail}>
              {translate('registration.changeEmail')}
            </Button>
          </Stack>
          {isEmailChanged && (
            <chakra.p>{translate('registration.emailChanged')}</chakra.p>
          )}
        </Box>
      )}
    </Box>
  )
}
