import { Box, Spinner } from '@chakra-ui/react'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import React, { FC, useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { About } from './About'

export const BaseLayout: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [isSignedIn, setIsSignedIn] = useState<boolean | undefined>()

  useEffect(() => {
    return onAuthStateChanged(getAuth(), (user) => {
      setIsSignedIn(Boolean(user))

      if (!user && location.pathname !== '/') {
        navigate('/')
      }
    })
  }, [location])

  if (isSignedIn === undefined) {
    return (
      <Box display="flex" h="100%" alignItems="center" justifyContent="center">
        <Spinner />
      </Box>
    )
  }

  if (!isSignedIn) {
    return <About />
  }

  return <Outlet />
}
