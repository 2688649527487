import { ja } from './ja'

export const en: typeof ja = {
  global: {
    siteName: 'ImageMart',
  },
  auth: {
    signIn: 'Sign in',
    signOut: 'Sign out',
    failedToSignIn: 'Failed to sign in',
    signInWithGoogle: 'Sign in with Google',
    signInNotice: 'Only invited people can sign in.',
    signOutConfirmation: 'Are you sure to sign out?',
    cancelSigningOut: 'Cancel',
    aboutTerms1: 'By clicking button below, you agree our ',
    terms: 'terms of service',
    aboutTerms2: '.',
  },
  about: {
    description:
      'ImageMart is a web service to generate images from images, with AI power.',
    betaVersion: {
      title: 'About beta version',
      description:
        "Please register email address. We'll send you invitation when beta version become available for you.",
      emailPlaceholder: 'Email',
      submitButtonText: 'Register',
      registered: 'Registered!',
    },
    sampleImages: 'Sample images',
  },
  status: {
    waiting: 'waiting',
    processing: 'processing',
    done: 'done',
    failed: 'failed',
  },
  home: {
    requestImageGenerationTitle: 'Request to generate image',
    withUrl: 'With image URL',
    withLocalFile: 'With local file',
    urlPlaceholder: 'Image url',
    requestImageGeneration: 'Request',
    createdAt: 'Created',
    doneAt: 'Done',
    status: 'Status',
    inputImage: 'Input image',
    generatedImages: 'Generated images',
    copyrightNotice: "This image is not violating others' copyrights.",
    notice:
      'This service is currently beta version.\nImages are generated with slightly low quality, in order to provide experience for all users.',
  },
  registration: {
    invalidUrl: 'Invalid URL.',
    alreadyRegistered: 'Already registered.',
    title: 'Welcome to ImageMart',
    errorMessage1:
      "Failed to sign in. Google account's email may differ from address that invitation sent.",
    errorMessage2: 'Please update email address and try again.',
    emailPlaceholder: 'Email',
    changeEmail: 'Change',
    emailChanged: 'Email address was changed.',
  },
}
