import { Box } from '@chakra-ui/react'

<Box maxW='600px' mx='auto' p='2'>

# ImageMart 利用規約

この利用規約（以下「本規約」といいます。）は、akami 株式会社（以下 「当社」といいます。）がこのウェブサイト上で提供するサービス（以下 「本サービス」といいます。）の利用条件を定めるものです。登録ユーザーの皆さま（以下 「ユーザー」といいます。）には、本規約に従って本サービスをご利用いただきます。

## 第 1 条（適用）

本規約は、ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。
当社は本サービスに関し、本規約のほか、ご利用にあたってのルール等、各種の定め（以下、「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず、本規約の一部を構成するものとします。
本規約の規定が前条の個別規定の規定と矛盾する場合には、個別規定において特段の定めなき限り、個別規定の規定が優先されるものとします。

## 第 2 条（利用登録）

本サービスにおいては、登録希望者が本規約に同意の上、当社の定める方法によって利用登録を申請し、当社がこれを承認することによって、利用登録が完了するものとします。
当社は、利用登録の申請者に以下の事由があると判断した場合、利用登録の申請を承認しないことがあり、その理由については一切の開示義務を負わないものとします。

- 利用登録の申請に際して虚偽の事項を届け出た場合
- 本規約に違反したことがある者からの申請である場合
- その他、当社が利用登録を相当でないと判断した場合

## 第 3 条（ユーザー ID およびパスワードの管理）

ユーザーは、自己の責任において、本サービスのユーザー ID およびパスワードを適切に管理するものとします。
ユーザーは、いかなる場合にも、ユーザー ID およびパスワードを第三者に譲渡または貸与し、もしくは第三者と共用することはできません。当社は、ユーザー ID とパスワードの組み合わせが登録情報と一致してログインされた場合には、そのユーザー ID を登録しているユーザー自身による利用とみなします。
ユーザー ID 及びパスワードが第三者によって使用されたことによって生じた損害は、当社に故意又は重大な過失がある場合を除き、当社は一切の責任を負わないものとします。

## 第 4 条（利用料金および支払方法）

ユーザーは、本サービスの有料部分の対価として、当社が別途定め、本ウェブサイトに表示する利用料金を、当社が指定する方法により支払うものとします。
ユーザーが利用料金の支払を遅滞した場合には、ユーザーは年 14．6％の割合による遅延損害金を支払うものとします。

## 第 5 条（禁止事項）

ユーザーは、本サービスの利用にあたり、以下の行為をしてはなりません。

- 法令または公序良俗に違反する行為
- 犯罪行為に関連する行為
- 本サービスの内容等、本サービスに含まれる著作権、商標権ほか知的財産権を侵害する行為
- 当社、ほかのユーザー、またはその他第三者のサーバーまたはネットワークの機能を破壊したり、妨害したりする行為
- 当社のサービスの運営を妨害するおそれのある行為
- 不正アクセスをし、またはこれを試みる行為
- 他のユーザーに関する個人情報等を収集または蓄積する行為
- 不正な目的を持って本サービスを利用する行為
- 本サービスの他のユーザーまたはその他の第三者に不利益、損害、不快感を与える行為
- 他のユーザーに成りすます行為
- 当社が許諾しない本サービス上での宣伝、広告、勧誘、または営業行為
- 面識のない異性との出会いを目的とした行為
- 当社のサービスに関連して、反社会的勢力に対して直接または間接に利益を供与する行為
- その他、当社が不適切と判断する行為

## 第 6 条（本サービスの提供の停止等）

当社は、以下のいずれかの事由があると判断した場合、ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。

- 本サービスにかかるコンピュータシステムの保守点検または更新を行う場合
- 地震、落雷、火災、停電または天災などの不可抗力により、本サービスの提供が困難となった場合
- コンピュータまたは通信回線等が事故により停止した場合
- その他、当社が本サービスの提供が困難と判断した場合

当社は、本サービスの提供の停止または中断により、ユーザーまたは第三者が被ったいかなる不利益または損害についても、一切の責任を負わないものとします。

## 第 7 条（利用制限および登録抹消）

当社は、ユーザーが以下のいずれかに該当する場合には、事前の通知なく、ユーザーに対して、本サービスの全部もしくは一部の利用を制限し、またはユーザーとしての登録を抹消することができるものとします。

- 本規約のいずれかの条項に違反した場合
- 登録事項に虚偽の事実があることが判明した場合
- 料金等の支払債務の不履行があった場合
- 当社からの連絡に対し、一定期間返答がない場合
- 本サービスについて、最終の利用から一定期間利用がない場合
- その他、当社が本サービスの利用を適当でないと判断した場合

当社は、本条に基づき当社が行った行為によりユーザーに生じた損害について、一切の責任を負いません。

## 第 8 条（退会）

ユーザーは当社の定める退会手続により、本サービスから退会できるものとします。

## 第 9 条（保証の否認および免責事項）

当社は、本サービスに事実上または法律上の瑕疵（安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。
当社は、本サービスに起因してユーザーに生じたあらゆる損害について、当社の故意又は重過失による場合を除き、一切の責任を負いません。ただし、本サービスに関する当社とユーザーとの間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合、この免責規定は適用されません。
当社は、本サービスに関して、ユーザーと他のユーザーまたは第三者との間において生じた取引、連絡または紛争等について一切責任を負いません。

## 第 10 条（サービス内容の変更等）

当社は、事前の通知なく、本サービスの内容を変更、追加または廃止することがあり、ユーザーはこれを承諾するものとします。

## 第 11 条（利用規約の変更）

当社は以下の場合には、ユーザーの個別の同意を要せず、本規約を変更することができるものとします。

- 本規約の変更がユーザーの一般の利益に適合するとき。
- 本規約の変更が本サービス利用契約の目的に反せず、かつ、変更の必要性、変更後の内容の相当性その他の変更に係る事情に照らして合理的なものであるとき。

当社はユーザーに対し、前項による本規約の変更にあたり、事前に、本規約を変更する旨及び変更後の本規約の内容並びにその効力発生時期を通知します。

## 第 12 条（個人情報の取扱い）

当社は、本サービスの利用によって取得する個人情報については、当社「プライバシーポリシー」に従い適切に取り扱うものとします。

## 第 13 条（通知または連絡）

ユーザーと当社との間の通知または連絡は、当社の定める方法によって行うものとします。当社は,ユーザーから,当社が別途定める方式に従った変更届け出がない限り,現在登録されている連絡先が有効なものとみなして当該連絡先へ通知または連絡を行い,これらは,発信時にユーザーへ到達したものとみなします。

## 第 14 条（AI 出力物の権利等）

AI 出力物については、送信データに関する権利を有する者がその知的財産権を有するものとします。
ユーザーは、商用、非商用を問わず、自己の操作により生成された AI 出力物を自ら利用することができるとともに、第三者に公開することができます。
公開設定にしている AI 出力物に限り、ユーザーは当社が本サービスのプロモーション等の用途に無償で利用することに同意するものとします。
前項の規定にかかわらず、当社は、公開、非公開の設定に関わらず、全ての AI 出力物を閲覧することができ、また、当社が不適切であると判断した AI 出力物を自由に削除又は公開停止等の措置を講ずることができるものとし、ユーザーは、あらかじめ同意するものとします。
ユーザーは、当社に対して、送信データが著作権法を含む法令等及び本規約に違反していないことを表明し保証するものとします。ユーザーが、第三者から本サービスの利用に関し権利侵害等の主張その他何らかの請求等を受けた場合、直ちにその旨を当社に通知するとともに、当該請求等について、自らの負担と責任のもとで解決しなければならないものとします。なお、当社が第三者から権利侵害の通報等を受けた場合には、対象のユーザーを特定したうえで、当社の裁量により、対象ユーザーによるサービス利用の停止、AI 出力物の削除等必要な措置を講ずることができるものとします。

## 第 15 条（権利義務の譲渡の禁止）

ユーザーは、当社の書面による事前の承諾なく、利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し、または担保に供することはできません。

## 第 16 条（準拠法・裁判管轄）

本規約の解釈にあたっては、日本法を準拠法とします。
本サービスに関して紛争が生じた場合には、当社の本店所在地を管轄する裁判所を専属的合意管轄とします。

以上

</Box>
