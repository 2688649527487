import { FormControl } from '@chakra-ui/form-control'
import {
  Box,
  Button,
  chakra,
  FormErrorMessage,
  Heading,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { css } from '@emotion/react'
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth'
import {
  addDoc,
  collection,
  getDocs,
  getFirestore,
  serverTimestamp,
} from 'firebase/firestore'
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik'
import React, { FC, useCallback, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { translate } from '../i18n'
import { call } from '../utils/call'
import { shuffle } from '../utils/shuffle'
import { Link } from './Link'

const schema = Yup.object().shape({
  email: Yup.string()
    .email('メールアドレスの形式が正しくありません')
    .required('メールアドレスが入力されていません'),
})

type FormValues = { email: string }

type GalleryImage = {
  id: string
  image: string
}

export const About: FC = () => {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast()
  const [galleryImages, setGalleryImages] = useState<Array<GalleryImage>>([])

  useEffect(() => {
    call(async () => {
      const snapshot = await getDocs(
        collection(getFirestore(), 'galleryImages')
      )
      setGalleryImages(
        shuffle(
          snapshot.docs.map((doc) => ({ id: doc.id, image: doc.data().image }))
        )
      )
    })
  }, [])

  const signIn = useCallback(async () => {
    try {
      await signInWithPopup(getAuth(), new GoogleAuthProvider())
    } catch (e) {
      toast({ title: 'ログインに失敗しました', status: 'error' })
      return
    }
    onClose()
  }, [onClose, toast])

  const onSubmit = useCallback(
    async ({ email }: FormValues, actions: FormikHelpers<FormValues>) => {
      try {
        await addDoc(collection(getFirestore(), 'invitationRequests'), {
          email,
          createdAt: serverTimestamp(),
        })
      } catch (e) {
        console.error(e)
      }
      actions.setSubmitting(false)
      setIsSubmitted(true)
    },
    []
  )

  return (
    <Box overflowY="auto" h="100%">
      <Box bg="gray.300" position="sticky" top="0" zIndex={1}>
        <Box
          h="60px"
          px={4}
          py={2}
          mx="auto"
          maxW="600px"
          display="flex"
          alignItems="center"
        >
          <chakra.h1 fontSize={28} fontWeight="bold" color="gray.700" flex="1">
            {translate('global.siteName')}
          </chakra.h1>
          <Button onClick={onOpen} size="sm">
            {translate('auth.signIn')}
          </Button>
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{translate('auth.signIn')}</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <chakra.p mb={1}>{translate('auth.signInNotice')}</chakra.p>
                <chakra.p mb={1}>
                  {translate('auth.aboutTerms1')}
                  <Link to="/terms" target="_blank">
                    {translate('auth.terms')}
                  </Link>
                  {translate('auth.aboutTerms2')}
                </chakra.p>
                <Button onClick={signIn}>{translate('auth.signIn')}</Button>
              </ModalBody>
            </ModalContent>
          </Modal>
        </Box>
      </Box>
      <Box p={4} maxW="600px" mx="auto">
        <chakra.p>{translate('about.description')}</chakra.p>
        <Heading as="h2" size="lg" mt={2}>
          {translate('about.betaVersion.title')}
        </Heading>
        <chakra.p>{translate('about.betaVersion.description')}</chakra.p>
        <Formik
          initialValues={{ email: '' }}
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, errors, touched }) => (
            <Form>
              <Field name="email">
                {({ field }: FieldProps) => (
                  <FormControl
                    isInvalid={Boolean(errors.email && touched.email)}
                  >
                    <Input
                      {...field}
                      placeholder={translate(
                        'about.betaVersion.emailPlaceholder'
                      )}
                    />
                    <FormErrorMessage>{errors.email}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              {isSubmitted ? (
                <chakra.p>{translate('about.betaVersion.registered')}</chakra.p>
              ) : (
                <Button
                  mt={4}
                  colorScheme="teal"
                  isLoading={isSubmitting}
                  type="submit"
                >
                  {translate('about.betaVersion.submitButtonText')}
                </Button>
              )}
            </Form>
          )}
        </Formik>

        <Heading as="h2" size="lg" mt={4} mb={1}>
          {translate('about.sampleImages')}
        </Heading>

        <Stack direction="row" spacing={0} flexWrap="wrap">
          {galleryImages.map(({ id, image }) => (
            <Image
              key={id}
              src={image}
              w="25%"
              maxH="150px"
              fit="cover"
              css={css`
                aspect-ratio: 1;
              `}
            />
          ))}
        </Stack>
      </Box>
    </Box>
  )
}
