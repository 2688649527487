import {
  chakra,
  ChakraProvider,
  Heading,
  ListItem,
  OrderedList,
  UnorderedList,
} from '@chakra-ui/react'
import { MDXProvider, MDXProviderComponents } from '@mdx-js/react'
import React, { FC } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Gallery } from './Admin/Gallery'
import { AdminHome } from './Admin/Home'
import { InvitationRequests } from './Admin/InvitationRequests'
import { AdminLayout } from './Admin/Layout'
import { BaseLayout } from './BaseLayout'
import { Home } from './Home'
import { Registration } from './Registration'
import TOS from './TOS.mdx'

const components: MDXProviderComponents = {
  h1: (props) => <Heading as="h1" size="2xl" mt={5} mb={3} {...props} />,
  h2: (props) => <Heading as="h2" size="xl" mt={5} mb={3} {...props} />,
  h3: (props) => <Heading as="h3" size="lg" mt={5} mb={3} {...props} />,
  p: (props) => <chakra.p my={2} whiteSpace="pre-wrap" {...props} />,
  ul: (props) => <UnorderedList {...props} />,
  ol: (props) => <OrderedList {...props} />,
  li: (props) => <ListItem {...props} />,
}

export const App: FC = () => (
  <BrowserRouter>
    <ChakraProvider>
      <MDXProvider components={components}>
        <Routing />
      </MDXProvider>
    </ChakraProvider>
  </BrowserRouter>
)

const Routing: FC = () => (
  <Routes>
    <Route path="/" element={<BaseLayout />}>
      <Route index element={<Home />} />
    </Route>
    <Route path="register" element={<Registration />} />
    <Route path="admin" element={<AdminLayout />}>
      <Route index element={<AdminHome />} />
      <Route path="invitationRequests" element={<InvitationRequests />} />
      <Route path="gallery" element={<Gallery />} />
    </Route>
    <Route path="terms" element={<TOS />} />
  </Routes>
)
