import { Box, Button, chakra } from '@chakra-ui/react'
import {
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithPopup,
} from 'firebase/auth'
import React, { FC, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { Link } from '../Link'

export const AdminLayout: FC = () => {
  const [isSignedIn, setIsSignedIn] = useState<boolean | undefined>()

  useEffect(() => {
    return onAuthStateChanged(getAuth(), (user) => {
      setIsSignedIn(user !== null)
    })
  }, [])
  return (
    <Box maxW="600px" mx="auto">
      <chakra.p>
        <Link to="/admin">管理画面</Link>
      </chakra.p>
      {isSignedIn ? (
        <Outlet></Outlet>
      ) : (
        <Button
          onClick={() => signInWithPopup(getAuth(), new GoogleAuthProvider())}
        >
          ログイン
        </Button>
      )}
    </Box>
  )
}
