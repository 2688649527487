import { Box, Button, Heading, Image, Stack } from '@chakra-ui/react'
import { css } from '@emotion/react'
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  startAfter,
  Timestamp,
} from 'firebase/firestore'
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { call } from '../../utils/call'

type GalleryImage = {
  id: string
  image: string
  sequence: number
}
type GeneratedImage = {
  id: string
  image: string
}

export const Gallery: FC = () => {
  const galleryImagesCollection = useMemo(
    () => collection(getFirestore(), 'galleryImages'),
    []
  )
  const generatedImagesCollection = useMemo(
    () => collection(getFirestore(), 'generatedImages'),
    []
  )

  const [galleryImages, setGalleryImages] = useState<Array<GalleryImage>>([])
  const [generatedImages, setGeneratedImages] = useState<Array<GeneratedImage>>(
    []
  )
  const galleryImagesSet = useMemo(
    () => new Set(galleryImages.map(({ image }) => image)),
    [galleryImages]
  )
  const lastPage = useRef<Timestamp | undefined>()

  useEffect(() => {
    call(async () => {
      const gallerySnapshot = await getDocs(
        query(galleryImagesCollection, orderBy('sequence', 'asc'))
      )
      setGalleryImages(
        gallerySnapshot.docs.map((doc) => {
          const { image, sequence } = doc.data()
          return {
            id: doc.id,
            image,
            sequence,
          }
        })
      )

      const generatedSnapshot = await getDocs(
        query(
          generatedImagesCollection,
          orderBy('createdAt', 'desc'),
          limit(200)
        )
      )
      setGeneratedImages(
        generatedSnapshot.docs.map((doc) => ({
          id: doc.id,
          image: doc.data().image,
        }))
      )
      lastPage.current =
        generatedSnapshot.docs.length === 200
          ? generatedSnapshot.docs.at(-1)?.data().createdAt
          : undefined
    })
  }, [galleryImagesCollection, generatedImagesCollection])

  const add = useCallback(
    async (image: string) => {
      const it = galleryImages.find((img) => img.image === image)
      if (it) return

      const sequence =
        Math.max(-1, ...galleryImages.map((img) => img.sequence)) + 1

      const doc = await addDoc(galleryImagesCollection, { image, sequence })
      setGalleryImages((images) =>
        images.concat({ id: doc.id, image, sequence })
      )
    },
    [galleryImages, galleryImagesCollection]
  )

  const remove = useCallback(
    async (id: string, i: number) => {
      await deleteDoc(doc(galleryImagesCollection, id))

      setGalleryImages((images) => {
        const newImages = images.slice()
        newImages.splice(i, 1)
        return newImages
      })
    },
    [galleryImagesCollection]
  )

  const loadMore = useCallback(async () => {
    const snapshot = await getDocs(
      query(
        generatedImagesCollection,
        orderBy('createdAt', 'desc'),
        limit(200),
        startAfter(lastPage.current)
      )
    )
    const newImages: Array<GeneratedImage> = snapshot.docs.map((doc) => ({
      id: doc.id,
      image: doc.data().image,
    }))
    setGeneratedImages((images) => images.concat(newImages))
    lastPage.current =
      snapshot.docs.length === 200
        ? snapshot.docs.at(-1)?.data().createdAt
        : undefined
  }, [generatedImagesCollection])

  return (
    <Box pb={2}>
      <Heading as="h2" size="md">
        トップに表示する画像管理
      </Heading>

      <Heading as="h3" size="sm" mt={4}>
        表示する画像 (クリックして削除)
      </Heading>

      <Stack direction="row" flexWrap="wrap" spacing="0">
        {galleryImages.map(({ id, image }, i) => (
          <Image
            key={id}
            src={image}
            onClick={remove.bind(null, id, i)}
            w="150px"
            cursor="pointer"
            fit="cover"
            css={css`
              aspect-ratio: 1;
            `}
          />
        ))}
      </Stack>

      <Heading as="h3" size="sm" mt={4}>
        生成された画像 (クリックして表示に追加)
      </Heading>

      <Stack direction="row" flexWrap="wrap" spacing="0">
        {generatedImages.map(({ id, image }) => (
          <Image
            key={id}
            src={image}
            onClick={add.bind(null, image)}
            w="150px"
            cursor="pointer"
            opacity={galleryImagesSet.has(image) ? 1.0 : 0.5}
            fit="cover"
            css={css`
              aspect-ratio: 1;
            `}
          />
        ))}
      </Stack>

      {lastPage.current && (
        <Button onClick={loadMore} display="block" mx="auto" my={2}>
          さらに読み込む
        </Button>
      )}
    </Box>
  )
}
