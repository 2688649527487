import {
  Box,
  Button,
  chakra,
  Heading,
  Stack,
  useClipboard,
} from '@chakra-ui/react'
import {
  collection,
  doc,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  Timestamp,
  updateDoc,
} from 'firebase/firestore'
import React, { FC, useCallback, useEffect, useState } from 'react'

type Request = {
  id: string
  email: string
  createdAt: Date
  isApproved: boolean
}

export const InvitationRequests: FC = () => {
  const [invitationRequests, setInvitationRequests] = useState<Array<Request>>(
    []
  )

  useEffect(() => {
    return onSnapshot(
      query(
        collection(getFirestore(), 'invitationRequests'),
        orderBy('createdAt', 'desc')
      ),
      (snapshot) => {
        const requests = snapshot.docs.map((doc) => {
          const { email, createdAt, isApproved } = doc.data()
          return {
            id: doc.id,
            email,
            createdAt: (createdAt as Timestamp).toDate(),
            isApproved: Boolean(isApproved),
          }
        })
        setInvitationRequests(requests)
      }
    )
  }, [])

  const approve = useCallback(async (id: string) => {
    await updateDoc(doc(getFirestore(), 'invitationRequests', id), {
      isApproved: true,
    })
  }, [])

  return (
    <Box pb={2}>
      <Heading as="h2" size="md">
        招待管理
      </Heading>

      <chakra.p>合計: {invitationRequests.length}件</chakra.p>

      {invitationRequests.map((request) => (
        <Item
          key={request.id}
          request={request}
          onApprove={approve.bind(null, request.id)}
        />
      ))}
    </Box>
  )
}

const Item: FC<{ request: Request; onApprove: () => void }> = ({
  request: { id, email, createdAt, isApproved },
  onApprove,
}) => {
  const { hasCopied, onCopy } = useClipboard(
    `https://app.imagemart.net/register?invitation=${id}`
  )

  return (
    <Box border="1px" borderColor="gray" rounded="md" p={2} my={3}>
      <chakra.p>email: {email}</chakra.p>
      <chakra.p>createdAt: {createdAt.toISOString()}</chakra.p>
      {isApproved ? (
        <chakra.p>招待済み</chakra.p>
      ) : (
        <Stack direction="row">
          <Button onClick={onApprove}>招待する</Button>
          <Button onClick={onCopy}>
            {hasCopied ? 'コピーしました' : 'URLをコピーする'}
          </Button>
        </Stack>
      )}
    </Box>
  )
}
