import { Link as ChakraLink } from '@chakra-ui/react'
import React, { ComponentProps, FC } from 'react'
import { Link as RouterLink } from 'react-router-dom'

export const Link: FC<ComponentProps<typeof RouterLink>> = (props) => (
  <ChakraLink
    as={RouterLink}
    {...props}
    textDecoration="underline"
    color="blue"
  />
)
