import { RefCallback, RefObject, useCallback, useRef } from 'react'

export function useSmartRef<T>(
  effect: (e: T) => (() => void) | undefined
): [RefObject<T>, RefCallback<T>] {
  const element = useRef<T | null>(null)
  const cleanup = useRef<(() => void) | undefined>()

  const callback: RefCallback<T> = useCallback(
    (e) => {
      if (e === null) return

      cleanup.current?.()
      cleanup.current = effect(e)
    },
    [effect]
  )

  return [element, callback]
}
