import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3'
import {
  Alert,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertIcon,
  Box,
  Button,
  chakra,
  Checkbox,
  Heading,
  Input,
  Radio,
  RadioGroup,
  Stack,
  useDisclosure,
} from '@chakra-ui/react'
import { format } from 'date-fns'
import { getAuth, signOut } from 'firebase/auth'
import {
  addDoc,
  collection,
  getDocs,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  Timestamp,
  where,
} from 'firebase/firestore'
import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { translate } from '../i18n'
import { call } from '../utils/call'
import { useSmartRef } from '../utils/useSmartRef'

const s3 = new S3Client({
  region: 'ap-northeast-1',
  endpoint: 'https://s3.ap-northeast-1.wasabisys.com',
  credentials: {
    accessKeyId: 'O63MO1U90C8MQ84L579O',
    secretAccessKey: 'c9SDqgvQos4LV6WrVwazZN1zHfpWnHpbCDLwQDtF',
  },
  logger: console,
})
const imageUrlPrefix = 'https://imagemart.s3.ap-northeast-1.wasabisys.com'

type ImageGenerationRequest = {
  id: string
  userId: string
  createdAt: Date
  doneAt: Date | undefined
  status: 'waiting' | 'processing' | 'done' | 'failed'
  inputImage: string
}

const statusToText = {
  waiting: translate('status.waiting'),
  processing: translate('status.processing'),
  done: translate('status.done'),
  failed: translate('status.failed'),
}

export const Home: FC = () => {
  const [method, setMethod] = useState<'file' | 'url'>('url')
  const [imageUrl, setImageUrl] = useState<string | undefined>()
  const [file, setFile] = useState<File | undefined>()
  const [image, setImage] = useState<string | undefined>()
  const [sending, setSending] = useState(false)
  const [requests, setRequests] = useState<Array<ImageGenerationRequest>>([])
  const [agreed, setAgreed] = useState(false)

  const { open: openLogoutDialog, dialog: logoutDialog } = useLogoutDialog(
    async () => {
      await signOut(getAuth())
    }
  )

  const [ref, refCallback] = useSmartRef<HTMLInputElement>((e) => {
    const onChange = (e: Event) => {
      console.log('onChange')
      const file = (e.target as HTMLInputElement).files?.[0]
      setFile(file)
      if (file) {
        const reader = new FileReader()
        reader.onload = () => {
          setImage(reader.result as string)
        }
        reader.readAsDataURL(file)
      } else {
        setImage(undefined)
      }
    }

    e.addEventListener('change', onChange)
    return () => {
      e.removeEventListener('change', onChange)
    }
  })

  useEffect(() => {
    if (method === 'url') {
      setFile(undefined)
      setImage(undefined)
    }
  }, [method])

  const onClick = useCallback(async () => {
    const user = getAuth().currentUser
    if (user === null) return

    setSending(true)

    let finalImageUrl: string | undefined
    if (method === 'url') {
      finalImageUrl = imageUrl
    } else if (method === 'file' && file) {
      const ext =
        file.name.match(/\.(png|jpe?g|gif)$/i)?.[1].toLowerCase() ?? 'png'
      const key = `test/${generateId()}.${ext}`
      finalImageUrl = `${imageUrlPrefix}/${key}`

      await s3.send(
        new PutObjectCommand({
          Bucket: 'imagemart',
          Key: key,
          Body: file,
          ACL: 'public-read',
        })
      )
    }

    if (finalImageUrl === undefined) {
      setSending(false)
      return
    }

    await addDoc(collection(getFirestore(), 'imageGenerationRequests'), {
      userId: user.uid,
      inputImage: finalImageUrl,
      createdAt: serverTimestamp(),
      doneAt: null,
      status: 'waiting',
      priority: 0,
    })

    setSending(false)
    setFile(undefined)
    setImage(undefined)
    setImageUrl(undefined)
    const e = ref.current
    if (e) e.value = ''
  }, [method, file, ref, imageUrl])

  useEffect(() => {
    const user = getAuth().currentUser
    if (user === null) return

    return onSnapshot(
      query(
        collection(getFirestore(), 'imageGenerationRequests'),
        where('userId', '==', user.uid),
        orderBy('createdAt', 'desc')
      ),
      (snapshot) => {
        if (snapshot.metadata.hasPendingWrites) return

        setRequests(
          snapshot.docs.map((d) => {
            const { createdAt, doneAt, inputImage, ...rest } = d.data()

            return {
              id: d.id,
              ...rest,
              createdAt: (createdAt as Timestamp).toDate(),
              doneAt: (doneAt as Timestamp | null)?.toDate(),
              inputImage: inputImage.startsWith('/')
                ? `${imageUrlPrefix}/${inputImage.slice(1)}`
                : inputImage,
            } as ImageGenerationRequest
          })
        )
      }
    )
  }, [])

  return (
    <Box overflowY="auto" h="100%" bg="gray.50">
      <Box bg="gray.300" position="sticky" top="0" zIndex={1}>
        <Box
          h="60px"
          px={4}
          py={2}
          mx="auto"
          maxW="600px"
          display="flex"
          alignItems="center"
        >
          <chakra.h1 fontSize={28} fontWeight="bold" color="gray.700" flex="1">
            {translate('global.siteName')}
          </chakra.h1>
          <Button onClick={openLogoutDialog} size="sm">
            {translate('auth.signOut')}
          </Button>
          {logoutDialog}
        </Box>
      </Box>
      <Box p={4} maxW="600px" mx="auto">
        <Alert status="warning">
          <AlertIcon />
          <chakra.p whiteSpace="pre-wrap">{translate('home.notice')}</chakra.p>
        </Alert>
        <Box p={2} mt={4} rounded="md" boxShadow="md" bg="white">
          <Heading as="h2" size="sm" mb={2}>
            {translate('home.requestImageGenerationTitle')}
          </Heading>
          <RadioGroup
            value={method}
            onChange={(val) => {
              if (val === 'url' || val === 'file') setMethod(val)
              else setMethod('url')
            }}
            mb={2}
          >
            <Stack direction="row">
              <Radio value="url">{translate('home.withUrl')}</Radio>
              <Radio value="file">{translate('home.withLocalFile')}</Radio>
            </Stack>
          </RadioGroup>
          {method === 'url' && (
            <Input
              type="text"
              size="md"
              placeholder={translate('home.urlPlaceholder')}
              value={imageUrl ?? ''}
              onChange={(e) => setImageUrl(e.target.value)}
            />
          )}
          {method === 'file' && (
            <>
              <Input type="file" accept="image/*" size="md" ref={refCallback} />
              {image && (
                <Box>
                  <chakra.img src={image} />
                </Box>
              )}
            </>
          )}
          <Stack direction="row" mt={2} spacing={5}>
            <Button onClick={onClick} disabled={sending || !agreed}>
              {translate('home.requestImageGeneration')}
            </Button>
            <Checkbox
              isChecked={agreed}
              onChange={(e) => setAgreed(e.target.checked)}
            >
              {translate('home.copyrightNotice')}
            </Checkbox>
          </Stack>
        </Box>
        {requests.map((req) => (
          <Request key={req.id} request={req} />
        ))}
      </Box>
    </Box>
  )
}

function generateId(): string {
  return Array.from(crypto.getRandomValues(new Uint8Array(16)))
    .map((n) => String(n).padStart(2, '0'))
    .join('')
}

function formatDate(date: Date): string {
  return format(date, 'yyyy年M月d日 HH:mm')
}

const Request: FC<{ request: ImageGenerationRequest }> = ({
  request: { id, status, createdAt, doneAt, inputImage },
}) => {
  const [images, setImages] = useState<Array<{ id: string; url: string }>>([])

  useEffect(() => {
    if (status !== 'done') return

    call(async () => {
      const images = await getDocs(
        query(
          collection(getFirestore(), 'generatedImages'),
          where('requestId', '==', id),
          orderBy('sequence', 'asc')
        )
      )
      setImages(images.docs.map((d) => ({ id: d.id, url: d.data().image })))
    })
  }, [id, status])

  return (
    <Box p={2} mt={2} rounded="md" boxShadow="md" bg="white">
      <chakra.div>
        <>
          {translate('home.createdAt')}: {formatDate(createdAt)}
        </>
      </chakra.div>
      {doneAt && (
        <chakra.div>
          <>
            {translate('home.doneAt')}: {formatDate(doneAt)}
          </>
        </chakra.div>
      )}
      <chakra.div>
        <>
          {translate('home.status')}: {statusToText[status]}
        </>
      </chakra.div>
      <chakra.div>{translate('home.inputImage')}:</chakra.div>
      <chakra.img src={inputImage} maxW="min(250px, 100%)" />
      {status === 'done' && (
        <>
          <chakra.div>{translate('home.generatedImages')}:</chakra.div>
          <chakra.div>
            {images.map((im) => (
              <chakra.img
                key={im.id}
                src={im.url}
                maxW="min(250px, 100%)"
                mr={1}
                display="inline-block"
              />
            ))}
          </chakra.div>
        </>
      )}
    </Box>
  )
}

function useLogoutDialog(callback: () => void | Promise<void>): {
  open: () => void
  dialog: ReactElement
} {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const cancelRef = useRef<HTMLButtonElement | null>(null)

  const dialog = (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {translate('auth.signOut')}
          </AlertDialogHeader>

          <AlertDialogBody>
            {translate('auth.signOutConfirmation')}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              {translate('auth.cancelSigningOut')}
            </Button>
            <Button
              colorScheme="red"
              onClick={async () => {
                await callback()
                onClose()
              }}
              ml={3}
            >
              {translate('auth.signOut')}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )

  return { open: onOpen, dialog }
}
